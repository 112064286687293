@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.hero {
	padding-left: 2rem;
}

.hero__wrapper {
	width: 100%;
	padding: 5rem 3rem;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.hero__title {
	max-width: 30rem;
	font-weight: 900;
	color: colors.$white;
	text-align: left;
	text-transform: uppercase;
}

@media screen and (min-width: structure.$tablet-landscape) {
	.post__content-grid--is-blog .hero {
		padding-left: unset;
	}

	.hero__wrapper {
		width: calc(100vw - structure.$size-sidebar - 7rem - 10rem - 2rem);
		padding: 7.45rem;
	}

	.post__content-grid--is-blog .hero__wrapper {
		width: calc(100vw - structure.$size-sidebar - 7rem - 10rem);
	}

	.hero__title {
		max-width: 50rem;
	}
}
